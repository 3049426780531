var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { notifications } from '@mantine/notifications';
import { CognitoUser, CognitoRefreshToken, CognitoUserPool, } from 'amazon-cognito-identity-js';
import axios from 'axios';
import Cookies from 'js-cookie';
const createCognitoUserPool = ({ userPoolId, clientId }) => {
    return new CognitoUserPool({
        UserPoolId: userPoolId,
        ClientId: clientId,
    });
};
export function setTokens({ username, accessToken, refreshToken, gameStoreId, accountId, }) {
    const expireAccess = new Date();
    expireAccess.setTime(expireAccess.getTime() + 30 * 60 * 1000);
    const expireDefault = new Date();
    expireDefault.setTime(expireDefault.getTime() + 24 * 60 * 60 * 1000);
    Cookies.set('emailPlayer', username, {
        expires: expireDefault,
        sameSite: 'strict',
    });
    Cookies.set('accessTokenPlayer', accessToken, {
        expires: expireAccess,
        sameSite: 'strict',
    });
    Cookies.set('refreshTokenPlayer', refreshToken, {
        expires: expireDefault,
        sameSite: 'strict',
    });
    Cookies.set('gameStoreIdPlayer', gameStoreId, {
        expires: expireDefault,
        sameSite: 'strict',
    });
    Cookies.set('accountIdPlayer', accountId, {
        expires: expireDefault,
        sameSite: 'strict',
    });
}
export function removeTokens() {
    Cookies.remove('emailPlayer');
    Cookies.remove('accessTokenPlayer');
    Cookies.remove('refreshTokenPlayer');
    Cookies.remove('gameStoreIdPlayer');
    Cookies.remove('accountIdPlayer');
}
let refreshPromise = null;
export function refreshTokens() {
    if (refreshPromise) {
        return refreshPromise;
    }
    refreshPromise = new Promise((resolve, reject) => {
        const refreshToken = Cookies.get('refreshTokenPlayer');
        const email = Cookies.get('emailPlayer');
        if (!refreshToken || !email) {
            notifications.show({
                title: 'Sessão Expirada',
                message: 'Ocorreu um erro ao atualizar sua sessão automaticamente, por favor, faça login novamente.',
                color: 'red',
            });
            window.location.href = '/';
            refreshPromise = null;
            reject(new Error('Missing refresh token or email'));
        }
        function performTokenRefresh() {
            return __awaiter(this, void 0, void 0, function* () {
                try {
                    const { data } = yield axios.get(`${process.env.REACT_APP_API_BASE_URL || ''}/public/initial-data`);
                    if (!data.player_app_user_pool_id ||
                        !data.player_app_user_pool_client_id) {
                        throw new Error('Invalid initial data');
                    }
                    const userPool = createCognitoUserPool({
                        userPoolId: data.player_app_user_pool_id,
                        clientId: data.player_app_user_pool_client_id,
                    });
                    if (!email || !refreshToken)
                        return;
                    const user = new CognitoUser({
                        Username: email,
                        Pool: userPool,
                    });
                    const cognitoRefreshToken = new CognitoRefreshToken({
                        RefreshToken: refreshToken,
                    });
                    user.refreshSession(cognitoRefreshToken, (err, session) => {
                        if (err) {
                            notifications.show({
                                title: 'Ops...',
                                message: 'Não foi possível renovar sua sessão, por favor faça o login novamente',
                                color: 'red',
                            });
                            window.location.href = '/';
                            refreshPromise = null;
                            reject(err);
                        }
                        else {
                            const newAccessToken = session.getAccessToken().getJwtToken();
                            const newRefreshToken = session.getRefreshToken().getToken();
                            setTokens({
                                username: email,
                                accessToken: newAccessToken,
                                refreshToken: newRefreshToken,
                                gameStoreId: data.game_store_id,
                                accountId: data.account_id,
                            });
                            refreshPromise = null;
                            resolve(newAccessToken);
                        }
                    });
                }
                catch (error) {
                    notifications.show({
                        title: 'Ops...',
                        message: 'Ocorreu um erro ao atualizar sua sessão automaticamente, por favor, faça login novamente.',
                        color: 'red',
                    });
                    window.location.href = '/';
                    refreshPromise = null;
                    reject(error);
                }
            });
        }
        performTokenRefresh().finally(() => {
            refreshPromise = null;
        });
    });
    return refreshPromise;
}
