import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Badge } from '@gameficame/ui-kit/src/components';
const statusTexts = {
    green: 'components.badgeStatus.success',
    yellow: 'components.badgeStatus.processing',
    red: 'components.badgeStatus.error',
};
export const statusColors = {
    ACTIVE: 'green',
    PROCESSING: 'yellow',
    FAILED: 'red',
};
function BadgeStatus({ status }) {
    const { t } = useTranslation();
    const color = statusColors[status] || 'red';
    const textKey = statusTexts[color];
    return (_jsx(Badge, Object.assign({ "data-testid": "badge-status", badgeColor: color }, { children: t(textKey) })));
}
export default BadgeStatus;
