var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable camelcase */
import React, { createContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { notifications } from '@mantine/notifications';
import { CognitoUser, AuthenticationDetails, CognitoUserPool, } from 'amazon-cognito-identity-js';
import Cookies from 'js-cookie';
import { useInitialData } from '~/layouts/hooks/useInitialData';
import { removeTokens, setTokens } from './TokenStoregeService';
export const AuthContext = createContext({});
export function AuthProvider({ children }) {
    const navigate = useNavigate();
    const { data: initialData, isLoading, isError } = useInitialData();
    const createCognitoUserPool = ({ player_app_user_pool_id, player_app_user_pool_client_id, }) => {
        return new CognitoUserPool({
            UserPoolId: player_app_user_pool_id,
            ClientId: player_app_user_pool_client_id,
        });
    };
    const cognitoUserPool = useMemo(() => {
        if (!isLoading && !isError && initialData) {
            return createCognitoUserPool(initialData);
        }
        return null;
    }, [initialData, isLoading, isError]);
    const signIn = ({ Username, Password, }) => __awaiter(this, void 0, void 0, function* () {
        return new Promise((resolve, reject) => {
            if (!cognitoUserPool) {
                reject(new Error('CognitoUserPool is not avaible.'));
                return;
            }
            const user = new CognitoUser({
                Username,
                Pool: cognitoUserPool,
            });
            const authDetails = new AuthenticationDetails({
                Username,
                Password,
            });
            user.authenticateUser(authDetails, {
                onSuccess: (data) => {
                    var _a, _b;
                    resolve(data);
                    setTokens({
                        username: Username,
                        accessToken: data.getAccessToken().getJwtToken(),
                        refreshToken: data.getRefreshToken().getToken(),
                        gameStoreId: (_a = initialData === null || initialData === void 0 ? void 0 : initialData.game_store_id) !== null && _a !== void 0 ? _a : '',
                        accountId: (_b = initialData === null || initialData === void 0 ? void 0 : initialData.account_id) !== null && _b !== void 0 ? _b : '',
                    });
                    navigate('/panel');
                },
                onFailure: (err) => {
                    reject(err);
                },
            });
        });
    });
    const logout = () => __awaiter(this, void 0, void 0, function* () {
        const user = cognitoUserPool === null || cognitoUserPool === void 0 ? void 0 : cognitoUserPool.getCurrentUser();
        if (user) {
            user.signOut();
            removeTokens();
            navigate('/');
        }
    });
    // Send email verification code to change password
    const forgotPassword = ({ username }) => __awaiter(this, void 0, void 0, function* () {
        return new Promise((resolve, reject) => {
            if (!cognitoUserPool) {
                reject(new Error('CognitoUserPool is not avaible.'));
                return;
            }
            const user = new CognitoUser({
                Username: username,
                Pool: cognitoUserPool,
            });
            Cookies.set('emailPlayer', username);
            user.forgotPassword({
                onSuccess: (data) => {
                    resolve(data);
                },
                onFailure: (err) => {
                    reject(err);
                },
                inputVerificationCode: (data) => {
                    resolve(data);
                    navigate('/reset-password');
                },
            });
        });
    });
    // Change password using email verification code
    const resetPassword = ({ code, password, }) => __awaiter(this, void 0, void 0, function* () {
        return new Promise((resolve, reject) => {
            var _a;
            if (!cognitoUserPool) {
                reject(new Error('CognitoUserPool is not avaible.'));
                return;
            }
            const Username = (_a = Cookies.get('emailPlayer')) !== null && _a !== void 0 ? _a : '';
            const user = new CognitoUser({
                Username,
                Pool: cognitoUserPool,
            });
            user.confirmPassword(code, password, {
                onSuccess: (data) => {
                    resolve(data);
                    navigate('/');
                },
                onFailure: (err) => {
                    reject(err);
                },
            });
        });
    });
    // Confirm email
    const confirmEmail = ({ code, username, }) => __awaiter(this, void 0, void 0, function* () {
        return new Promise((resolve, reject) => {
            if (!cognitoUserPool) {
                reject(new Error('CognitoUserPool is not available.'));
                return;
            }
            const user = new CognitoUser({
                Username: username,
                Pool: cognitoUserPool,
            });
            user.confirmRegistration(code, true, (err) => {
                if (err) {
                    if (err.code === 'ExpiredCodeException') {
                        navigate(`/request-new-code?email=${encodeURIComponent(username)}`);
                        return;
                    }
                    notifications.show({
                        title: 'Ops...',
                        message: 'Algo deu errado ao tentar confirmar seu e-mail.',
                        color: 'red',
                    });
                    navigate('/');
                    return;
                }
                resolve();
            });
        });
    });
    // Resend email confirmation code
    const resendConfirmationEmail = ({ username, }) => __awaiter(this, void 0, void 0, function* () {
        return new Promise(() => {
            if (!cognitoUserPool) {
                throw new Error('CognitoUserPool is not available.');
            }
            const user = new CognitoUser({
                Username: username,
                Pool: cognitoUserPool,
            });
            user.resendConfirmationCode((err) => {
                if (err) {
                    notifications.show({
                        title: 'Ops...',
                        message: 'Algo deu errado ao tentar reenviar o e-mail de confirmação. Por favor, tente novamente. Se o erro persistir, contate o suporte.',
                        color: 'red',
                    });
                    navigate('/');
                    return;
                }
                notifications.show({
                    title: 'Sucesso!',
                    message: 'Um novo link de confirmação foi enviado para o seu endereço de e-mail.',
                    color: 'green',
                });
                navigate('/');
            });
        });
    });
    const auth = useMemo(() => ({
        signIn,
        logout,
        forgotPassword,
        resetPassword,
        campaignId: initialData === null || initialData === void 0 ? void 0 : initialData.campaign_id,
        accountId: initialData === null || initialData === void 0 ? void 0 : initialData.account_id,
        primaryColor: initialData === null || initialData === void 0 ? void 0 : initialData.theme.primary_color,
        confirmEmail,
        resendConfirmationEmail,
    }), [
        signIn,
        logout,
        forgotPassword,
        resetPassword,
        initialData === null || initialData === void 0 ? void 0 : initialData.campaign_id,
        initialData === null || initialData === void 0 ? void 0 : initialData.account_id,
        initialData === null || initialData === void 0 ? void 0 : initialData.theme.primary_color,
        confirmEmail,
        resendConfirmationEmail,
    ]);
    return _jsx(AuthContext.Provider, Object.assign({ value: auth }, { children: children }));
}
