import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import ReactDOM from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { MantineProvider } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { StyleSheetManager } from 'styled-components';
import { i18n } from '~/layouts/i18n';
import { GlobalStyle } from '~/layouts/styles/styles';
import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import '@mantine/notifications/styles.css';
import App from './App';
import { ThemeProvider } from './layouts/contexts';
const root = ReactDOM.createRoot(document.getElementById('root'));
const queryClient = new QueryClient();
root.render(_jsx(React.StrictMode, { children: _jsx(StyleSheetManager, Object.assign({ shouldForwardProp: () => true }, { children: _jsx(QueryClientProvider, Object.assign({ client: queryClient }, { children: _jsx(ThemeProvider, { children: _jsx(MantineProvider, { children: _jsxs(I18nextProvider, Object.assign({ i18n: i18n }, { children: [_jsx(GlobalStyle, {}), _jsx(App, {}), _jsx(Notifications, { autoClose: 5000, position: "top-center" })] })) }) }) })) })) }));
