import styled from 'styled-components';
export const Container = styled.div.withConfig({
    shouldForwardProp: (prop) => prop !== 'sideBar',
}) `
  background-color: ${({ theme }) => theme.colors.purple900};
  position: absolute;
  height: 100vh;
  right: 0px;
  width: 314px;
  right: ${({ sideBar }) => (sideBar ? '0' : '-100%')};
  animation: showSidebar 0.4s;
  z-index: 5;

  @keyframes showSidebar {
    from {
      opacity: 0;
      width: 0;
    }
    to {
      opacity: 1;
      width: 314px;
    }
  }
`;
export const Content = styled.div `
  border-color: red;
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  gap: 40px;
  padding-left: 40px;
`;
export const BackgroundOverlay = styled.div.withConfig({
    shouldForwardProp: (prop) => prop !== 'sideBar',
}) `
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 4;
  pointer-events: ${({ sideBar }) => (sideBar ? 'auto' : 'none')};
  display: ${({ sideBar }) => (sideBar ? 'auto' : 'none')};
`;
