import styled from 'styled-components';
import container from '~/layouts/styles/container';
import BasePage from '~/pages/BasePage';
export const Container = styled(BasePage) `
  ${container}
  min-height: 100vh;
  width: 100%;
  min-width: 370px;
`;
export const Content = styled.div `
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;
  padding-left: 25px;
  padding-right: 25px;

  padding-top: 40px;
  padding-bottom: 40px;

  @media (min-width: 950px) {
    padding-left: 40px;
    padding-right: 40px;
  }

  @media (min-width: 1550px) {
    padding-left: 200px;
    padding-right: 200px;
  }
`;
