import styled from 'styled-components';
export const Footer = styled.div `
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.colors.grey100};
`;
export const Navbar = styled.nav `
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const NavMenu = styled.ul `
  display: flex;
  list-style: none;
  justify-content: flex-end;
  gap: 40px;
  height: 100%;
`;
export const NavMenuItem = styled.li `
  min-width: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const FooterSubContainer = styled.div `
  min-width: 350px;
  max-width: 1500px;
  margin: 0 auto;
  height: 100px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 40px 25px;

  @media (min-width: 950px) {
    padding: 40px 100px;
  }

  @media (min-width: 1550px) {
    padding: 40px 200px;
  }
`;
