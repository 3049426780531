var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useAuth } from '~/layouts/hooks';
import { useGetCampaignById } from '~/layouts/hooks/useCampaign';
import { handleGotoPolicyTerms, imageDomain } from '~/utils';
import { Footer } from '../Footer';
import { Header } from '../Header';
import * as Styled from './styles';
const DefaultLayout = (_a) => {
    var { children } = _a, rest = __rest(_a, ["children"]);
    const { campaignId } = useAuth();
    const { data } = useGetCampaignById(campaignId !== null && campaignId !== void 0 ? campaignId : undefined, {
        enabled: !!campaignId,
    });
    return (_jsxs(Styled.Container, Object.assign({}, rest, { children: [_jsx(Header, {}), _jsx(Styled.Content, { children: children }), _jsx(Footer, { onClickRegulation: () => {
                    if (data === null || data === void 0 ? void 0 : data.campaign.regulation.url) {
                        window.open(`${imageDomain}/${data.campaign.regulation.url}`, '_blank');
                    }
                }, onClickTerms: handleGotoPolicyTerms })] })));
};
export default DefaultLayout;
