import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { PrivateRouteContainer } from '~/layouts/containers';
import { useInitialData } from '~/layouts/hooks/useInitialData';
function RouteWrapper({ component: Component, isProtectedLayout = false, }) {
    const { data } = useInitialData();
    const location = useLocation();
    const pagesTitle = {
        '/': 'Login',
        '/recover-password': 'Recuperar conta',
        '/reset-password': 'Mudar senha',
        '/signup': 'Criar conta',
        '/panel': 'Painel',
        '/campaign': 'Campanha',
        '/history': 'Histórico',
        '/statement': 'Extrato',
        '/prizes': 'Meus prêmios',
        '/profile': 'Meu perfil',
        '/convite': 'Convide Amigos',
        '/raffle': 'Sorteios',
        '/member-get-member': 'Convide e Ganhe',
    };
    const club = `${data === null || data === void 0 ? void 0 : data.account_name} | `;
    const title = club
        ? `${club + pagesTitle[location.pathname]}`
        : pagesTitle[location.pathname];
    useEffect(() => {
        document.title = title;
    }, [club, location]);
    if (isProtectedLayout) {
        return (_jsx(PrivateRouteContainer, Object.assign({ redirectTo: "/" }, { children: _jsx(Component, {}) })));
    }
    return _jsx(Component, {});
}
export default RouteWrapper;
