import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { refreshTokens } from '~/layouts/contexts/AuthProvider/TokenStoregeService';
const PrivateRouteContainer = ({ children, redirectTo, }) => {
    const accessToken = Cookies.get('accessTokenPlayer');
    const refreshToken = Cookies.get('refreshTokenPlayer');
    if (!accessToken && refreshToken) {
        refreshTokens();
    }
    return (!accessToken && !refreshToken) || !refreshToken ? (_jsx(Navigate, { to: redirectTo })) : (children);
};
export default PrivateRouteContainer;
