var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '@gameficame/ui-kit/src/components';
import { useInitialData } from '~/layouts/hooks/useInitialData';
import * as Styled from './styles';
function Footer(_a) {
    var { onClickRegulation, onClickTerms } = _a, rest = __rest(_a, ["onClickRegulation", "onClickTerms"]);
    const { t } = useTranslation();
    const { data } = useInitialData();
    return (_jsx(Styled.Footer, Object.assign({}, rest, { children: _jsxs(Styled.FooterSubContainer, { children: [_jsx(Text, Object.assign({ fontSize: "14px", weight: "700", lineHeight: "17px", fontColor: "purple700", "data-testid": "footer-club" }, { children: data === null || data === void 0 ? void 0 : data.account_name })), _jsxs(Styled.NavMenu, { children: [_jsx(Styled.NavMenuItem, { children: _jsx(Text, Object.assign({ "data-testid": "regulation-link", fontSize: "14px", weight: "400", lineHeight: "17px", fontColor: "purple700", onClick: onClickRegulation }, { children: t('campaignRegulation.link') })) }), _jsx(Styled.NavMenuItem, { children: _jsx(Text, Object.assign({ "data-testid": "terms-link", fontSize: "14px", weight: "400", lineHeight: "17px", fontColor: "purple700", onClick: onClickTerms }, { children: t('terms.button') })) })] })] }) })));
}
export default Footer;
