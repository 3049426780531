import styled, { css } from 'styled-components';
export const Header = styled.div `
  width: 100%;
  background: ${({ theme }) => theme.colors.purple900};
`;
export const Image = styled.img `
  width: 178px;
  height: 42x;
  cursor: pointer;
`;
export const Navbar = styled.nav `
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const NavMenu = styled.ul `
  display: flex;
  list-style: none;
  gap: 24px;
  height: 100%;
`;
export const NavMenuItem = styled.li.withConfig({
    shouldForwardProp: (prop) => prop !== 'isActive',
}) `
  min-width: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 6px solid transparent;
  ${({ isActive }) => isActive &&
    css `
      border-bottom-color: ${({ theme }) => theme.colors.pink500};
    `};
`;
export const HeaderSubContainer = styled.div `
  max-width: 1500px;
  min-width: 350px;
  margin: 0 auto;
  height: 101px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 10;

  padding: 0px 40px;

  @media (min-width: 950px) {
    padding: 0px 40px;
  }

  @media (min-width: 1550px) {
    padding: 0px 200px;
  }
`;
