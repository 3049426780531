import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Grid, Group, Stack, Text, } from '@gameficame/ui-kit/src/components';
import { useAuth } from '~/layouts/hooks';
import * as Styled from './styles';
function LeftColumn({ image, title, campaignDate, description, onClickCampaignRegulation, }) {
    const { t } = useTranslation();
    const { primaryColor } = useAuth();
    return (_jsx(Grid.Col, Object.assign({ span: 6, bg: primaryColor || '#33245C', style: {
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
        }, mih: "100vh", p: 0 }, { children: _jsxs(Stack, Object.assign({ w: "100%", mih: "101vh", justify: "center", align: "center", right: 0 }, { children: [_jsxs(Stack, Object.assign({ w: "65%" }, { children: [image ? _jsx(Styled.Image, { src: image }) : null, _jsxs(Stack, Object.assign({ gap: "0px" }, { children: [title ? (_jsx(Text, Object.assign({ weight: "700", fontColor: "white100", fontSize: "32px", lineHeight: "42px" }, { children: title }))) : null, campaignDate ? (_jsx(Text, Object.assign({ weight: "400", fontColor: "white100", fontSize: "14px", lineHeight: "24px" }, { children: campaignDate }))) : null] })), description ? (_jsx(Text, Object.assign({ weight: "400", fontColor: "white100", fontSize: "18px", lineHeight: "28px" }, { children: description }))) : null] })), _jsx(Group, Object.assign({ w: "65%", align: "left" }, { children: _jsx(Button, Object.assign({ variant: "transparent", size: "sm", onClick: onClickCampaignRegulation }, { children: t('campaignRegulation.button') })) }))] })) })));
}
export default LeftColumn;
