import { useEffect, useState } from 'react';
/* eslint-disable no-plusplus */
const currentDomain = window.location.origin;
export const imageDomain = currentDomain.includes('localhost')
    ? currentDomain.replace('localhost:3000', 'gameficadev.net')
    : currentDomain;
export function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getUTCDate().toString().padStart(2, '0');
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
    const year = date.getUTCFullYear().toString().slice(2);
    return `${day}/${month}/${year}`;
}
export function formatTime(dateString) {
    const date = new Date(dateString);
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
}
export const language = () => {
    switch (navigator.language) {
        case 'pt-BR':
            return 'pt-BR';
        default:
            return 'en-US';
    }
};
export const isValidCPF = (value) => {
    let cpf = value;
    cpf = cpf.replace(/[^\d]+/g, '');
    if (cpf.length !== 11 || /^(\d)\1+$/.test(cpf)) {
        return false;
    }
    let sum = 0;
    for (let i = 1; i <= 9; i++) {
        sum += parseInt(cpf.substring(i - 1, i), 10) * (11 - i);
    }
    let rest = (sum * 10) % 11;
    if (rest === 10 || rest === 11)
        rest = 0;
    if (rest !== parseInt(cpf.substring(9, 10), 10)) {
        return false;
    }
    sum = 0;
    for (let i = 1; i <= 10; i++) {
        sum += parseInt(cpf.substring(i - 1, i), 10) * (12 - i);
    }
    rest = (sum * 10) % 11;
    if (rest === 10 || rest === 11)
        rest = 0;
    if (rest !== parseInt(cpf.substring(10, 11), 10)) {
        return false;
    }
    return true;
};
export const isValidSSN = (ssn) => {
    const ssnRegex = /^(?!666|000|9\d{2})\d{3}(?!00)\d{2}(?!0000)\d{4}$/;
    return ssnRegex.test(ssn);
};
export const formatSSNorCPF = (value) => {
    const cleanValue = value.replace(/\D/g, '');
    if (cleanValue.length <= 9) {
        return cleanValue
            .replace(/(\d{3})(\d{2})(\d{4})/, '$1-$2-$3')
            .substring(0, 11);
    }
    return cleanValue
        .replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
        .substring(0, 14);
};
export const formatAccessKey = (key) => {
    const trimmedKey = key.length > 54 ? key.substring(0, 54) : key;
    return trimmedKey.replace(/(\d{4})(?=\d)/g, '$1 ');
};
export const mechanicSourceTypeName = {
    GAMESTOREITEM_ADD: 'mechanicSourceTypes.refund',
    GAMESTOREITEM_REMOVE: 'mechanicSourceTypes.purchase',
    NONE_ADD: 'mechanicSourceTypes.manualAdd',
    NONE_REMOVE: 'mechanicSourceTypes.manualRemove',
    VOUCHER_ADD: 'mechanicSourceTypes.voucherRedeemed',
    NFE_ADD: 'mechanicSourceTypes.nfeRedeemed',
    LOTTERYDRAW_REMOVE: 'mechanicSourceTypes.lotteryDraw',
    MEMBERGETMEMBER_ADD: 'mechanicSourceTypes.mgm',
};
export function removePunctuation(value) {
    return value.replace(/[^\d]/g, '');
}
export const formatNumberToCurrency = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
});
export function validateColor(color) {
    const colorRegex = /^#([0-9A-Fa-f]{3}){1,2}$|^rgb\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3})\)$|^rgba\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3}),\s*(0|1|0?\.\d+)\)$|^hsl\(\d{1,3},\s*\d+%,\s*\d+%\)$|^hsla\(\d{1,3},\s*\d+%,\s*\d+%,\s*(0|1|0?\.\d+)\)$/;
    return colorRegex.test(color);
}
export const toBase64 = (value) => {
    return btoa(value);
};
export const base64ToString = (value) => {
    return atob(value);
};
export function useIsLargeScreen(breakpoint = 975) {
    const [isLargeScreen, setIsLargeScreen] = useState(window.matchMedia(`(min-width: ${breakpoint}px)`).matches);
    useEffect(() => {
        const mediaQuery = window.matchMedia(`(min-width: ${breakpoint}px)`);
        const handleResize = () => setIsLargeScreen(mediaQuery.matches);
        mediaQuery.addEventListener('change', handleResize);
        return () => mediaQuery.removeEventListener('change', handleResize);
    }, [breakpoint]);
    return isLargeScreen;
}
export function lightenColor(hexColor, amount) {
    if (hexColor) {
        const hex = hexColor.replace(/^#/, '');
        let r;
        let g;
        let b;
        if (hex.length === 3) {
            r = parseInt(hex[0] + hex[0], 16);
            g = parseInt(hex[1] + hex[1], 16);
            b = parseInt(hex[2] + hex[2], 16);
        }
        else {
            r = parseInt(hex.slice(0, 2), 16);
            g = parseInt(hex.slice(2, 4), 16);
            b = parseInt(hex.slice(4, 6), 16);
        }
        r = Math.min(255, Math.floor(r + (255 - r) * amount));
        g = Math.min(255, Math.floor(g + (255 - g) * amount));
        b = Math.min(255, Math.floor(b + (255 - b) * amount));
        const toHex = (color) => color.toString(16).padStart(2, '0');
        return `#${toHex(r)}${toHex(g)}${toHex(b)}`.toUpperCase();
    }
    return undefined;
}
export const handleGotoPolicyTerms = () => {
    window.open('https://gamefica.me/termos-de-uso-e-politica-de-privacidade/', '_blank');
};
