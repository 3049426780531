var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import api from '~/services/api';
function fetchCampaigns(status = '', text = '') {
    return __awaiter(this, void 0, void 0, function* () {
        const queryParams = [];
        if (status) {
            queryParams.push(`status=${status}`);
        }
        if (text) {
            queryParams.push(`text=${text}`);
        }
        const queryString = queryParams.length > 0 ? `?${queryParams.join('&')}` : '';
        const { data } = yield api.get(`/campaigns${queryString}`);
        return data;
    });
}
export const useGetCampaigns = ({ statusFilter = '', text = '' } = {}) => {
    const { status, data, isFetching, isPlaceholderData, error, refetch } = useQuery({
        queryKey: ['campaigns', statusFilter, text],
        queryFn: () => fetchCampaigns(statusFilter, text),
        placeholderData: keepPreviousData,
        gcTime: 0,
    });
    return { status, data, isFetching, isPlaceholderData, error, refetch };
};
export const useGetCampaignById = (id, options = {}) => {
    return useQuery(Object.assign({ queryKey: ['campaignsGetById'], queryFn: () => __awaiter(void 0, void 0, void 0, function* () {
            if (id) {
                const { data } = yield api.get(`/campaigns/${id}`);
                return data;
            }
            return null;
        }), gcTime: 0 }, options));
};
export const useGetCampaignByIdAndAccount = (id, accountId, options = {}) => {
    return useQuery(Object.assign({ queryKey: ['campaignsGetByIdAndAccount'], queryFn: () => __awaiter(void 0, void 0, void 0, function* () {
            if (id) {
                const { data } = yield api.get(`/public/campaigns/${id}/account/${accountId}`);
                return data;
            }
            return null;
        }), gcTime: 0 }, options));
};
